import { useEffect } from "react";
import "./app.css";
import "./custom.css";

export default () => {
    // const data = [{ id: 'd5b1591a-3ede-44b8-8e1a-5fd7621f3b99', name: 'Voodoo', available: true }];

    // const onOrder = (link) => {
    //   window.location.href = `https://order.tabin.co.nz/restaurant/${link}`;
    // };

    useEffect(() => {
        window.location.href = "https://order.tabin.co.nz/restaurant/6ec6dc5a-92ca-4499-9ed7-3cba7ab46d81";
    }, []);

    return (
        <div>Redirecting...</div>
        // <div className="restaurant-list">
        //   <img
        //     src={`https://tabin182909-prod.s3.ap-southeast-2.amazonaws.com/protected/ap-southeast-2%3A25450a52-7a40-45ea-a868-a9d98c6ff86a/2022-04-12_22%3A53%3A09.78-2022-04-10_02_52_03.649-image6.png`}
        //     className="restaurant-list-logo"
        //     alt="logo"
        //   />
        //   <div className="h3 text-center mb-6">
        //     At Voodoo we serve seriously delicious Mexican food made from quality ingredients. Click Order Here here to start!
        //   </div>
        //   {data &&
        //     data.map((restaurant, index) => (
        //       <div key={restaurant.id}>
        //         {index != 0 && <div className="separator-4"></div>}
        //         <div className="restaurant-list-item">
        //           <div className="restaurant-name">{restaurant.name}</div>
        //           <button
        //             className={`button ${restaurant.available ? '' : 'disabled'}`}
        //             onClick={() => {
        //               onOrder(restaurant.id);
        //             }}
        //           >
        //             {restaurant.available ? 'Order Here' : 'Unavailable'}
        //           </button>
        //         </div>
        //       </div>
        //     ))}
        // </div>
    );
};
